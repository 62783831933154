/* eslint-disable no-console */

import { register } from "register-service-worker";
import * as pkg from "../package.json";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // if version changed cleear cache
      if (localStorage.getItem(pkg.name) !== pkg.version) {
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name);
        });
        localStorage.setItem(pkg.name, process.env.VUE_APP_VERSION);
      }
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
