<template>
  <a-layout-header class="client-header">
    <!-- Collased button -->
    <a-button
      v-if="breakPoint"
      class="btn-collapse btn-sm-height-auto"
      @click="() => onCollapse(!!!layout.collapsed)"
      :icon="layout.collapsed ? 'menu-unfold' : 'menu-fold'"
      type="link"
      :class="{ mini: breakPoint }"
    />
    <div class="logo" v-if="!breakPoint">
      <router-link
        :to="{ name: 'home' }"
        :style="{ display: 'flex', justifyContent: 'center' }"
      >
        <img alt="Logo" :src="banner" />
      </router-link>
    </div>
    <a-menu
      v-if="!breakPoint"
      theme="dark"
      mode="horizontal"
      :default-selected-keys="['home']"
      class="client-menu"
      :class="{ mini: breakPoint }"
      :openKeys="layout.openKeys.filter((key) => !!key) || []"
      @select="onSelectMenuChange"
      :selectedKeys="layout.selectedKeys || []"
    >
      <!-- MENU -->
      <a-menu-item
        v-for="{ key, title } in menus.filter(
          ({ key }) => !['login', 'register'].includes(key)
        )"
        :key="key"
      >
        <span>{{ title }}</span>
        <router-link v-if="key" :to="{ name: key }" />
      </a-menu-item>
    </a-menu>
    <div class="action-menu">
      <template v-if="!loggedUser && !breakPoint">
        <a-button
          v-for="{ key, title } in menus.filter(({ key }) =>
            ['login', 'register'].includes(key)
          )"
          :type="key === 'register' ? 'link' : 'default'"
          :class="`btn-${key}`"
          :key="key"
          @click="goToRoute(key)"
        >
          <span>{{ title }}</span>
        </a-button>
      </template>
      <template v-if="loggedUser">
        <span class="btn-transaction-group">
          <router-link
            :to="{ name: 'balance-movements' }"
            :title="labels.header.balanceMovements"
          >
            <span class="btn-balance" :class="{ mini: breakPoint }">
              <span
                >{{ breakPoint ? "$" : labels.header.balance }}
                {{
                  (loggedUser.balance || 0) | formatMoney("", 2, ",", ".")
                }}</span
              >
              <a-button
                v-if="breakPoint"
                type="link"
                class="refresh-balance-link"
                @click.prevent="handleRefreshBalance"
                :disabled="syncing"
              >
                <a-icon type="sync" :spin="syncing" />
              </a-button>
            </span>
          </router-link>
          <a-button
            v-if="!breakPoint"
            type="primary"
            class="refresh-balance"
            @click="handleRefreshBalance"
            :disabled="syncing"
          >
            <a-icon type="sync" :spin="syncing" />
          </a-button>
          <a-button-group
            v-if="!breakPoint"
            v-bind="breakPoint && { size: 'small' }"
          >
            <a-button class="btn-deposit" @click="goToLink('DEPOSIT')">{{
              labels.header.deposit
            }}</a-button>
            <a-button
              v-if="!loggedUser.dww"
              class="btn-withdraw"
              @click="goToLink('WITHDRAW')"
              >{{ labels.header.withdraw }}</a-button
            >
          </a-button-group>
          <router-link
            v-if="false"
            :to="{ name: 'balance-movements' }"
            :title="labels.header.balanceMovements"
          >
            <span class="btn-balance" :class="{ mini: breakPoint }">
              <span
                >{{ breakPoint ? "$" : labels.header.balance }}
                {{
                  (loggedUser.balance || 0) | formatMoney("", 2, ",", ".")
                }}</span
              >
            </span>
          </router-link>
        </span>
        <a-dropdown
          v-if="breakPoint"
          placement="bottomCenter"
          class="transaction-dropdown"
        >
          <a-menu class="dropdown-menu" slot="overlay">
            <a-menu-item key="deposit" @click="goToLink('DEPOSIT')">{{
              labels.header.deposit
            }}</a-menu-item>
            <a-menu-item
              v-if="!loggedUser.dww"
              key="withdraw"
              @click="goToLink('WITHDRAW')"
              >{{ labels.header.withdraw }}</a-menu-item
            >
          </a-menu>
          <a-button class="dropdown-btn">
            <font-awesome-icon
              :icon="['fas', 'arrow-right-arrow-left']"
              class="anticon"
            />
          </a-button>
        </a-dropdown>
        <a-button
          :type="'link'"
          class="btn-cart"
          :class="{ mini: breakPoint }"
          @click="goToRoute('buy')"
        >
          <a-badge :count="plays.length || 0" title="Custom hover text">
            <a-icon type="shopping-cart" />
          </a-badge>
          <span v-if="!breakPoint" class="pl-24">{{
            labels.header.playNow
          }}</span>
        </a-button>
      </template>
      <a-popover
        v-if="loggedUser"
        v-model="visible"
        trigger="click"
        placement="bottomRight"
      >
        <div
          :style="{
            float: 'right',
            margin: '0 auto',
            userSelect: 'none',
          }"
          class="hover user-info"
        >
          <a-avatar
            v-if="fullName"
            :size="breakPoint ? 34 : 38"
            :style="{ backgroundColor: '#0ca750', color: '#FFF' }"
            >{{ getInitials(fullName) }}</a-avatar
          >
          <a-avatar
            v-else
            :style="{ backgroundColor: '#0ca750', color: '#FFF' }"
            icon="user"
          />
        </div>
        <template slot="title">
          <div class="content-user">
            <span class="content-user-name">{{ fullName }}</span>
            <span class="content-user-email">{{ loggedUser.email }}</span>
          </div>
        </template>
        <template slot="content">
          <a-menu
            :style="{ width: 'auto', borderRight: 'none' }"
            mode="vertical"
            theme="light"
            class="actions-menu"
            :selectedKeys="[]"
            @click="handleActionClick"
          >
            <a-menu-item key="ticket-list">
              <font-awesome-icon class="anticon" :icon="['fas', 'list']" />
              <span>{{ labels.header.userMenu.ticketList }}</span>
            </a-menu-item>
            <a-menu-item key="transaction-list">
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'money-bill-transfer']"
              />
              <span>{{ labels.header.userMenu.transferList }}</span>
            </a-menu-item>
            <a-menu-item key="balance-movements">
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'money-bill-wave']"
              />
              <span>{{ labels.header.userMenu.balanceMovements }}</span>
            </a-menu-item>
            <a-menu-item v-if="loggedUser.ecb" key="cash-balance-report">
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'file-invoice-dollar']"
              />
              <span>{{ labels.header.userMenu.cashBalanceReport }}</span>
            </a-menu-item>
            <a-menu-item key="referrals">
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'users-rays']"
              />
              <span>{{ labels.header.userMenu.referral }}</span>
            </a-menu-item>
            <a-divider :style="{ margin: '6px 0' }" />
            <a-menu-item key="change-password">
              <a-icon type="lock" />
              <span>{{ labels.header.userMenu.changePassword }}</span>
            </a-menu-item>
            <a-menu-item key="logout">
              <a-icon type="logout" />
              <span>{{ labels.header.userMenu.logout }}</span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-popover>

      <router-link
        v-if="!loggedUser && breakPoint"
        :to="{ name: 'login' }"
        class="ant-btn ant-btn-default btn-play-now"
        >{{ labels.header.playNow }}</router-link
      >
    </div>
  </a-layout-header>
</template>
<script>
import { menus } from "@/router/client-menus.js";
import { stringInitials } from "@/utils/utils";
import { mapActions, mapGetters, mapState } from "vuex";
import labels from "@/utils/labels";
import banner from "@/assets/images/admin/banner.png";
import _ from "lodash";
export default {
  name: "AppHeader",
  data() {
    return {
      visible: false,
      labels: { ...labels.layout },
      banner,
      syncing: false,
    };
  },
  created() {
    this.updateLayout({
      ...this.layout,
      selectedKeys: [this.$route.name],
    });
  },
  methods: {
    ...mapActions("layout", ["updateLayout"]),
    ...mapActions("clientAuth", ["handleLogout", "refreshBalance"]),
    getInitials(name) {
      return stringInitials(name);
    },
    async handleActionClick({ key }) {
      switch (key) {
        case "referrals":
          this.goToRoute("referrals");
          break;
        case "ticket-list":
          this.goToRoute("ticket-list");
          break;
        case "change-password":
          this.goToRoute("change-password");
          break;
        case "transaction-list":
          this.goToRoute("transaction-list");
          break;
        case "balance-movements":
          this.goToRoute("balance-movements");
          break;
        case "cash-balance-report":
          if (this.loggedUser.ecb) this.goToRoute("cash-balance-report");
          break;
        case "logout":
          try {
            await this.handleLogout();
          } catch (error) {
            console.error(error);
          } finally {
            this.goToRoute("login");
          }
          break;
      }
      this.visible = false;
    },
    onCollapse(collapsed = true) {
      this.updateLayout({
        ...this.layout,
        collapsed: collapsed,
      });
    },
    onSelectMenuChange({ key }) {
      this.updateLayout({
        ...this.layout,
        selectedKeys: [key],
      });
    },
    goToRoute(key, params = {}) {
      if (this.$route.name !== key)
        this.$router.push({ name: key, query: params });
    },
    goToLink(type) {
      const route = this.$router.resolve({
        name: "transaction-request",
        query: { type },
      });
      if (route.href !== this.$route.fullPath) this.$router.push(route.href);
    },
    handleRefreshBalance() {
      this.syncing = true;
      this.refreshBalance().finally(() => {
        this.syncing = false;
      });
    },
  },
  computed: {
    ...mapGetters("layout", ["getLayoutDataStore"]),
    ...mapGetters("clientAuth", ["getLoggedUser"]),
    ...mapState("cart", ["plays"]),
    layout() {
      return this.getLayoutDataStore;
    },
    menus() {
      const { accessRoles = [] } = this.loggedUser || {};
      return menus(accessRoles).filter(
        (menu) => !menu?.hidden && !menu?.hiddenHeader
      );
    },
    breakPoint() {
      return this.layout.screenWidth <= 1199;
    },
    loggedUser() {
      return this.getLoggedUser;
    },
    fullName() {
      const { firstname = "", lastname = "" } = this.loggedUser;
      return `${firstname} ${lastname}`;
    },
  },
  watch: {
    $route(to, from) {
      if (!_.isEqual(to, from)) {
        this.updateLayout({
          ...this.layout,
          selectedKeys: [to.name],
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.client-header {
  position: fixed;
  height: 74px !important;
  z-index: 3 !important;
  width: 100%;
  background-color: color(c-primary);
  border-bottom: 1px solid color(--white-light);
  @media screen and (max-width: $screen-mobile) {
    height: 61px !important;
    justify-content: space-between;
    align-items: center;
    display: flex;
    transition: 0.5s ease-in-out;
  }
  .client-menu {
    line-height: 74px;
    background: transparent;
    .ant-menu-item {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875rem;
      color: color(--white);
      &-selected {
        color: color(c-hover);
        background-color: transparent !important;
      }
      &-active {
        color: color(c-hover);
        background-color: color(--white-light) !important;
      }
    }
  }
  .logo {
    width: 207px;
    margin: 13px 15px 0 0;
    float: left;
    transition: 0.5s ease-in-out;
    img {
      width: 100%;
    }
    @media screen and (max-width: $screen-mobile) {
      margin: 0 15px 0 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.5s ease-in-out;
    }
  }
  .action-menu {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Roboto", sans-serif;
    &.mini {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }
    .btn-login {
      margin-right: 10px;
      border-radius: 50px !important;
      padding: 5px 20px;
      background-color: color(c-hover);
      border-color: color(c-hover);
      color: color(c-primary);
      font-weight: 500 !important;
      height: 36px;
    }
    .btn-register {
      color: color(--white);
      font-weight: 500 !important;
      &:hover {
        color: color(c-hover);
      }
    }
    .btn-cart {
      margin-right: 10px;
      border-radius: 0px !important;
      padding: 5px 24px;
      padding-left: 36px;
      background-color: transparent;
      color: color(c-hover);
      font-weight: 500 !important;
      height: 48px;
      &:hover {
        transition: 0.5s ease-in-out;
        border-color: color(c-hover);
      }
      .ant-badge {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 42px;
        margin: 0;
        padding: 0;
        line-height: 1;
        .ant-badge-count {
          right: 0;
          top: -2px;
        }
      }
      .pl-24 {
        margin-left: 24px;
      }
      &.mini {
        padding: 5px 20px;
        margin-right: 20px;
        height: 36px;
        border: none;
        font-weight: 400 !important;
        vertical-align: middle;
        .ant-badge {
          font-size: 30px;
        }
      }
    }
    .btn-balance {
      margin-right: 5px;
      border-radius: 0px !important;
      padding: 5px 24px;
      background-color: transparent;
      border: 1px solid color(c-hover);
      color: color(c-hover);
      font-weight: 500 !important;
      height: 48px;
      user-select: none;
      &.mini {
        padding: 5px 5px 5px 12px;
      }
    }
    .refresh-balance {
      margin-right: 10px;
      padding: 0 8px;
      font-size: 18px;
      font-weight: 500;
      background-color: color(c-hover);
      border: 1px solid color(c-hover);
      color: color(c-primary);
      &:focus,
      &:active,
      &:hover {
        background-color: color(c-hover);
        border: 1px solid color(c-hover);
        color: color(c-primary);
      }
    }
    .refresh-balance-link {
      padding: 0 12px;
      font-size: 14px;
      font-weight: 600;
      color: white;
      &:hover {
        color: color(c-hover);
      }
    }
    .user-info {
      font-family: "Roboto", sans-serif;
      color: color(--white);
      :hover {
        cursor: pointer;
      }
      &.mini {
        .ant-avatar-string {
          font-size: 16px !important;
        }
      }
    }
    @media screen and (max-width: $screen-mobile) {
      .user {
        &-info {
          .ant-avatar {
            margin-right: 0;
            transition: 0.5s ease-in-out;
          }
        }
      }
    }
  }
  .btn-collapse {
    color: color(--white);
    font-size: 22px;
    margin: 15px 24px 0 0;
    float: left;
    transition: 0.5s ease-in-out;
    @media screen and (max-width: $screen-mobile) {
      &.btn-sm-height-auto {
        height: auto;
        font-size: 22px;
        margin: 0 24px 0 0;
        .anticon {
          line-height: 1.4 !important;
          transition: 0.5s ease-in-out;
        }
        transition: 0.5s ease-in-out;
      }
    }
  }
  .btn-deposit {
    background-color: color(c-hover);
    border: 1px solid color(c-hover);
    color: color(c-primary);
    &:focus,
    &:active,
    &:hover {
      background-color: color(c-hover);
      border: 1px solid color(c-hover);
      color: color(c-primary);
    }
  }
  .btn-withdraw {
    background-color: color(c-primary);
    border: 1px solid color(c-hover);
    color: color(c-hover);
    &:focus,
    &:active,
    &:hover {
      background-color: color(c-primary);
      border: 1px solid color(c-hover);
      color: color(c-hover);
    }
  }
  .btn-transaction-group {
    margin-right: 10px;
    width: 52px !important;
    &.mini {
      width: auto !important;
      position: absolute;
      left: -140px;
      margin-right: 0;
      top: -5px;
    }
  }
  .btn-play-now {
    border-radius: 0px !important;
    background-color: color(c-hover);
    border-color: color(c-hover);
    color: color(c-primary);
    font-weight: 500 !important;
    margin-right: 0;
    &:focus,
    &:active,
    &:hover {
      background-color: color(c-hover);
      border-color: color(c-hover);
      color: color(c-primary);
    }
  }
  .text-right {
    text-align: right;
  }
}
.ant-popover-title {
  .content-user {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: normal;
    padding: 5px 15px;
    &-name {
      font-size: 1.2rem;
      font-weight: 600;
    }
    &-email {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
}
.dropdown-btn {
  background-color: color(c-primary);
  border: 1px solid color(c-hover);
  color: color(c-hover);
  font-size: 18px;
  padding: 0 8px;
  font-weight: 600;
  &:focus,
  &:active,
  &:hover {
    background-color: color(c-primary);
    border: 1px solid color(c-hover);
    color: color(c-hover);
  }
}
.dropdown-menu {
  .ant-dropdown-menu-item {
    font-weight: 600;
    font-size: 16px;
  }
}
@media screen and (max-width: $screen-mobile) {
  .ant-layout-header {
    padding: 0 15px !important;
    transition: 0.5s ease-in-out;
  }
}
</style>
